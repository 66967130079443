import React from "react";
import { List, ListItemText, TextField, Button, IconButton, Box, Typography, ListItem } from "@mui/material";
import { Delete, Add } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";


interface INameValueInputListProps {
    inputs: Record<string, string>[];
    setInputs: (inputs: Record<string, string>[]) => void;
    isEdit?: boolean;
    title: string;
}

export const NameValueInputList = ({ inputs, setInputs, isEdit = false, title }: INameValueInputListProps) => {

    const confirm = useConfirm();

    const handleAddInput = () => {
        setInputs([...inputs, { name: "", value: "" }]);
    };

    const handleRemoveInput = async (index: number) => {
        const { confirmed, reason } = await confirm({
            title: "Delete Item",
            description: "Are you sure? This cannot be undone.",
          });
      
          if (confirmed) {
            setInputs(inputs.filter((_, i) => i !== index));
          }
        
    };

    const handleValueChange = (index: number, value: string) => {
        const newInputs = [...inputs];
        newInputs[index] = { name: newInputs[index]['name'], value: value };
        setInputs(newInputs);
    };

    const handleNameChange = (index: number, name: string) => {
        const newInputs = [...inputs];
        newInputs[index] = { name: name, value: newInputs[index]['value'] };
        setInputs(newInputs);
    };


    return (
        <Box>
            <Box display="flex" sx={{ marginLeft: "10px" }}>
                <Typography variant="body1" sx={{ marginBottom: "10px" }}>{title}</Typography>
                {isEdit && <Button variant="contained" onClick={handleAddInput} sx={{ marginBottom: '20px', marginLeft: 'auto' }}><Add /></Button>}
            </Box>
            {isEdit ? (
                <Box display="flex" flexDirection="column" gap={2}>
                    {inputs.map((input, index) => (
                        <Box key={index} display="flex" alignItems="center" gap={1} sx={{ width: '440px', marginBottom: '16px' }}>
                            <>
                            <Typography variant="body1">{index + 1}:</Typography>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    value={input.name}
                                    onChange={(e) => handleNameChange(index, e.target.value)}
                                    required={true}
                                />
                                <TextField
                                    fullWidth
                                    label="Value"
                                    value={input.value}
                                    onChange={(e) => handleValueChange(index, e.target.value)}
                                    required={true}
                                />
                                <IconButton onClick={() => handleRemoveInput(index)} >
                                    <Delete />
                                </IconButton>
                            </>
                        </Box>
                    ))}
                </Box>
            ) : (
                <List>
                    {inputs.map((input, index) => (
                        <ListItem>
                            <Typography variant="body1" sx={{ marginRight: '10px' }}>{index + 1}:</Typography>
                            <ListItemText primary="Name" secondary={input.name ? input.name : '-'} sx={{ marginRight: "10px" }} />
                            <ListItemText primary="Value" secondary={input.value ? input.value : '-'} />
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};
