import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, Grid, Skeleton, Box } from '@mui/material';
import { editOutput, getSingleOutput, IOutput } from '../../services/outputsService';
import { EditedList } from '../../components/EditedList';
import { roleSelector, userIdSelector } from '../../redux/selectors/authSelector';
import { IEditedListItem, Roles } from '../../global/types/types';
import EditCard from '../../components/EditCard';
import { updatedSingleCustomer, convertFunction } from '../../utils';
import DropdownSelect from '../../components/Select';
import BackButton from '../../components/BackButton';
import { outputStatuses, outputTypes } from '../../global/constants/outputs';
import { NameValueInputList } from '../../components/NameValueInputList';
import validator from 'validator';


const SingleOutputPage = () => {
  const { id } = useParams();
  const role = useSelector(roleSelector);
  const authorizedUserId = useSelector(userIdSelector);

  const [output, setOutput] = useState<IOutput | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [requestError, setRequestError] = useState('');
  const [fieldList, setFieldList] = useState<IEditedListItem[]>([]);
  const [headersList, setHeadersList] = useState<Record<string, string>[]>([]);
  const [errors, setErrors] = useState<Record<string, string>[]>([]);
  const statuses = useMemo(() => convertFunction(outputStatuses), []);

  const convertToValidData = useCallback((outputData: IOutput) => {
    let dataItems = [
      {
        title: 'Name',
        info: outputData?.name,
        fieldName: 'name',
        required: true,
        isEditable: true,
      },
      {
        title: 'Type',
        info: outputData?.type,
        fieldName: 'type',
        isEditable: false,
      },
      {
        title: 'Customer Name',
        info: outputData?.company.name,
        isEditable: false,
      },
      {
        title: 'URL',
        fieldName: 'url',
        info: outputData?.settings.url,
        required: true,
        isEditable: true,
      },
      {
        title: 'Status',
        info: outputData?.status,
        Component: DropdownSelect,
        fieldName: 'status',
        selectValues: statuses,
        isEditable: true,
      },
      {
        title: 'Last Response from output recipient',
        fieldName: 'last_response',
        info: outputData?.last_response,
        isEditable: false,
      },
    ];
    return dataItems;
  }, []);

  useEffect(() => {
    const fetchSingleOutput = async () => {
      try {
        setIsLoading(true);
        const response = await getSingleOutput(id as string);

        if (response && response.status === 200) {
          setOutput(response.data.data);
          setFieldList(convertToValidData(response.data.data));

          setHeadersList(response.data.data.settings.headers);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSingleOutput();
  }, [convertToValidData, id]);

  const toggleEditModeHandle = useCallback(() => {
    setIsEdit((prevState) => !prevState);
  }, []);

  const isSaveDisabled = useMemo(
    () => {
      let returnValue = false;
      /*
      fieldList.some((element) => {
        if (element.isEditable && !element.info) returnValue = true;
      });
      if (!output) returnValue = true;*/
      if (output && output.type == outputTypes.HTTP) {
          headersList.some((element) => {
              if (!element.name || !element.value) returnValue = true;
          });
      }
      return returnValue;
    },
    [fieldList, headersList, output]
  );

  const submitHandle = useCallback(async () => {
    try {
      setIsLoading(true);
      const updatedData = updatedSingleCustomer(fieldList);
      const theUrl = updatedData.url ? updatedData.url.toString() : "";
      if (!validator.isURL(theUrl)) {
        throw new Error('Please enter a valid URL');
      }
      const response = await editOutput(id as string, {
        name: updatedData.name ? updatedData.name.toString() : null,
        status: updatedData.status ? updatedData.status.toString() : null,
        settings: { "url": updatedData.url ? updatedData.url.toString() : "", "headers": headersList },
      });

      if (response && response.status === 200) {
        setOutput(response.data.data);
        setRequestError('');
        toggleEditModeHandle();
      }
    } catch (e: any) {
      if ('error' in e) {
        setRequestError(e.error.message);
      } else {
        setRequestError(e.message);
      }
    } finally {
      setIsLoading(false);
      //toggleEditModeHandle();
    }
  }, [id, fieldList, headersList, toggleEditModeHandle]);

  const isIdMatched = useMemo(() => {
    return id === authorizedUserId;
  }, [id, authorizedUserId]);

  return (
    <Grid container>
      {isLoading ? (
        <Skeleton variant='rectangular' width={490} height={568} sx={{ borderRadius: '4px' }} />
      ) : (
        <>
          <BackButton to='/outputs' style={{ marginBottom: '20px' }} />

          {output && (
            <Grid container>
              {requestError && (
                <Grid container justifyContent='flex-start' alignItems='center' marginTop={2}>
                  <Alert severity='error'>{requestError}. Output was not edited</Alert>
                </Grid>
              )}
              <EditCard
                isEdit={isEdit}
                isDisabled={isSaveDisabled}
                isEditable={role.name !== Roles.USER || isIdMatched}
                errors={errors}
                toggleEditModeHandle={toggleEditModeHandle}
                onSubmitHandle={submitHandle}
              >
                <Box>
                  <EditedList fieldList={fieldList} isEdit={isEdit} setErrors={setErrors} setFieldList={setFieldList} />
                </Box>
                {output.type == outputTypes.HTTP && (
                  <Box>
                    <NameValueInputList inputs={headersList} setInputs={setHeadersList} isEdit={isEdit} title="Custom Headers" />
                  </Box>
                )}
              </EditCard>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default SingleOutputPage;
