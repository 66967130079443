import { Column } from '../../components/Table';

export const outputsColumns: Column[] = [
  { id: 'companyName', label: 'Customer Name', align: 'center', sortOrder: 'asc' },
  { id: 'name', label: 'Name', align: 'center', sortOrder: 'asc' },
  { id: 'type', label: 'Type', align: 'center', sortOrder: 'asc' },
  { id: 'status', label: 'Status', align: 'center', sortOrder: 'asc' },
  { id: 'last_response', label: 'Last Response', align: 'center', sortOrder: 'asc'},
];

export const outputStatuses = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const outputTypes = {
  HTTP: 'HTTP',
  //MQTT: 'MQTT'
};
