import axiosPrivate from '../api/axios';
import { AxiosResponse } from 'axios';
import { ICompanyObject } from '../global/types/types';
import { outputStatuses, outputTypes } from '../global/constants/outputs';
import { OutputsTableColumns } from '../global/types/outputs-types';
import { TSortParams } from '../redux/actions/outputsActionCreators/actionCreators';
import { convertOutputDataToSortQueue } from '../utils';

export interface IOutput {
  company: ICompanyObject;
  status: keyof typeof outputStatuses;
  name: string;
  id: string;
  settings: IHttpSettings;
  type: keyof typeof outputTypes;
  last_response: string;
}

export interface IHttpSettings {
  url: string;
  headers: Record<string, string>[]
}



export interface IResponse {
  message: string;
  statusCode: number;
  successful: boolean;
}

export interface ISingleOutputResponse extends IResponse {
  data: IOutput;
}

export interface IOutputResponse {
  data: IOutput[];
}

export interface ICreateOutputData {
  name: string;
  status: string;
  company: {
    id: string;
  };
  type: string;
  settings: IHttpSettings;
}

export interface ICreateOutputFields {
  name: string;
  status: string;
  company: {
    id: string;
  };
  type: string;
  url: string;
  headers: Record<string, string>[];
  tagNames: string[] | [];
}

export interface IUpdateOutputData {
  name: string | null;
  status: string | null;
  settings: IHttpSettings;
}


export const getOutputs = async (
  search: string,
  sort: { id: OutputsTableColumns; sort: TSortParams }[],
  page: number,
  rowPerPage: number,
  companyId: string,
  status: string
) => {
  return axiosPrivate.get(
    `/outputs/?search=${search}&${convertOutputDataToSortQueue(
      sort
    )}page=${page}&rowPerPage=${rowPerPage}&company=${companyId}&status=${status}`
  );
};

export const getSingleOutput = async (id: string): Promise<AxiosResponse<ISingleOutputResponse>> => {
  return axiosPrivate.get<ISingleOutputResponse>(`/outputs/${id}`);
};

export const createOutput = async (outputData: ICreateOutputData): Promise<AxiosResponse<{ data: IOutput }>> => {
  return axiosPrivate.post<{ data: IOutput }>('/outputs', outputData);
};

export const editOutput = async (id: string, outputData: IUpdateOutputData): Promise<AxiosResponse<{ data: IOutput }>> => {
  return axiosPrivate.put<{ data: IOutput }>(`/outputs/${id}`, outputData);
};
